import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Steps,
  Button,
  Space,
  Form,
  Radio,
  Input,
  Card,
  Tooltip,
  Row,
  Col,
  Checkbox,
  Typography,
  Descriptions,
  notification,
  Modal,
} from "antd";
import {
  CloudServerOutlined,
  LockOutlined,
  CreditCardOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import useSegment from "../../hooks/useSegment";
import {
  handleError,
  checkValidUsername,
  checkPasswordStrength,
  uppercaseFirst,
} from "../../helpers";
import { DEDICATED_SIZE_COLORS as SIZE_COLORS } from "../../constants";
import AmexCard from "../../images/cards/amex.svg";
import VisaCard from "../../images/cards/visa.svg";
import MastercardCard from "../../images/cards/mastercard.svg";
import DiscoverCard from "../../images/cards/discover.svg";
import StripeLogo from "../../images/cards/stripe.svg";
import MeteringBillingInfo from "../Account/MeteringBillingInfo";

const { Step } = Steps;
const { Password } = Input;
const { Paragraph, Text } = Typography;
const { confirm } = Modal;

const TYPES = [
  {
    label: "CPU",
    value: "cpu",
    style: { width: 97, textAlign: "center" },
  },
  {
    label: "GPU",
    value: "gpu",
    style: { width: 97, textAlign: "center" },
  },
];

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const getCardImage = (brand) => {
  switch (brand) {
    case "amex": {
      return AmexCard;
    }
    case "visa": {
      return VisaCard;
    }
    case "mastercard": {
      return MastercardCard;
    }
    case "discover": {
      return DiscoverCard;
    }
    default: {
      return null;
    }
  }
};

const DEFAULT_ADMIN_USERNAME = "kadmin";

const Wizard = ({ customer, products }) => {
  const stripe = useStripe();
  const elements = useElements();
  const auth = useAuth();
  const history = useHistory();
  const [form] = Form.useForm();
  const segment = useSegment(auth);

  const [current, setCurrent] = useState(0);
  const [passwordStrength, setPasswordStrength] = useState(false);

  const [type, setType] = useState(TYPES[0].value);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(false);
  const [enableAddCard, setEnableAddCard] = useState(false);
  const [paymentMethodsAdding, setPaymentMethodsAdding] = useState(false);
  const [databaseCreating, setDatabaseCreating] = useState(false);

  const [preauthAmount, setPreauthAmount] = useState(0);

  const [payload, setPayload] = useState({
    compute_product: null,
    storage_product: products.find(
      (product) => product.metadata.type === "storage"
    ),
    network_product: products.find(
      (product) => product.metadata.type === "network"
    ),
    admin_username: DEFAULT_ADMIN_USERNAME,
    admin_password: "",
    confirm_password: "",
    payment_method: null,
  });

  useEffect(() => {
    const fetchPreauthAmount = async () => {
      try {
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/config/stripe/preauth_amount`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPreauthAmount(resp.data.preauth_amount);
      } catch (error) {
        handleError(error);
        setPreauthAmount(0);
      }
    };

    const fetchPaymentMethods = async () => {
      try {
        setPaymentMethodsLoading(true);
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/customer`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { payment_methods } = resp.data;
        setPaymentMethods(payment_methods);
        if (payment_methods.length === 0) {
          setEnableAddCard(true);
        } else if (payment_methods.length >= 1) {
          const { default_payment_method = payment_methods[0].id } =
            customer.invoice_settings;
          setPayload((payload) => ({
            ...payload,
            payment_method: payment_methods.find(
              (method) => method.id === default_payment_method
            ),
          }));
          form.setFieldsValue({ payment_method_id: default_payment_method });
        }
      } catch (error) {
        handleError(error);
        setPaymentMethods([]);
        setEnableAddCard(true);
      } finally {
        setPaymentMethodsLoading(false);
      }
    };

    fetchPreauthAmount();
    fetchPaymentMethods();
  }, [auth, customer, form]);

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const renderPaymentMethod = (paymentMethod) => {
    const { card } = paymentMethod;
    return (
      <div>
        <img
          src={getCardImage(card.brand)}
          style={{ height: 24, marginRight: 5 }}
          alt={`${card.brand} Card`}
        />{" "}
        {uppercaseFirst(card.brand)} ending in {card.last4} Exp {card.exp_month}
        /{card.exp_year}{" "}
        {paymentMethod?.metadata?.preauth_id ? (
          <Tooltip title="Verified" placement="right">
            <CheckCircleOutlined style={{ color: "green" }} />
          </Tooltip>
        ) : (
          <Tooltip title="Unverified" placement="right">
            <CheckCircleOutlined style={{ color: "#cccccc" }} />
          </Tooltip>
        )}
      </div>
    );
  };

  const addPaymentMethod = async () => {
    setPaymentMethodsAdding(true);
    const token = await auth.getIdToken();
    let new_payment_method = null;

    try {
      // Segment
      segment.track("add_payment_method");

      const resp = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (resp.error) {
        handleError(resp.error.message);
      } else {
        // Reject prepaid cards
        if (["prepaid"].includes(resp.paymentMethod?.card?.funding)) {
          throw new Error(
            "Invalid payment method funding type: " +
              resp.paymentMethod?.card?.funding
          );
        }

        const resp2 = await axios.post(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/${resp.paymentMethod.id}/attach`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        new_payment_method = resp2.data.payment_method;

        if (preauthAmount > 0) {
          // Pre-authorize payment method
          const preauthResp = await axios.post(
            `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/${new_payment_method.id}/preauth`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          new_payment_method = preauthResp.data.payment_method;
        }

        const fetchPaymentMethods = async () => {
          try {
            setPaymentMethodsLoading(true);
            const token = await auth.getIdToken();
            const resp = await axios.get(
              `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/customer`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            const { payment_methods } = resp.data;
            setPaymentMethods(payment_methods);
            if (payment_methods.length === 0) {
              setEnableAddCard(true);
            }
          } catch (error) {
            handleError(error);
            setPaymentMethods([]);
            setEnableAddCard(true);
          } finally {
            setPaymentMethodsLoading(false);
          }
        };
        await fetchPaymentMethods();

        setEnableAddCard(false);

        setPayload({
          ...payload,
          payment_method: new_payment_method,
        });

        form.setFieldsValue({ payment_method_id: new_payment_method.id });
      }
    } catch (error) {
      if (new_payment_method) {
        await axios.post(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/${new_payment_method.id}/detach`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      handleError(error);
    } finally {
      setPaymentMethodsAdding(false);
    }
  };

  const handleAddPaymentMethod = async () => {
    if (elements.getElement(CardElement)) {
      if (preauthAmount > 0) {
        confirm({
          title: "Verification",
          icon: <ExclamationCircleOutlined />,
          content: `A hold authorization of $${(
            Math.round(preauthAmount) / 100
          ).toFixed(
            2
          )} is required to verify this payment method. The hold will be released within the next few business days. Click 'I Agree' if you would like to proceed.`,
          okText: "I Agree",
          onOk: addPaymentMethod,
          centered: true,
        });
      } else {
        addPaymentMethod();
      }
    }
  };

  const handleInstanceSizeSelect = (selection) => (event) => {
    const { compute_product } = payload;
    setPayload({
      ...payload,
      compute_product:
        compute_product === null || compute_product.id !== selection.id
          ? selection
          : null,
    });
  };

  const DollarFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const renderPaymentMethods = (paymentMethods) => {
    return (
      paymentMethods &&
      paymentMethods.length > 0 && (
        <Form.Item name="payment_method_id">
          <Radio.Group style={{ width: "100%" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              {paymentMethods.map((method) => {
                const { id, card } = method;
                const { payment_method } = payload;
                const selected = payment_method && payment_method.id === id;
                return (
                  <Radio
                    key={id}
                    value={id}
                    style={{
                      padding: "5px 15px",
                      width: "100%",
                      backgroundColor: selected ? "#ac92d611" : undefined,
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src={getCardImage(card.brand)}
                      style={{ height: 24, marginRight: 5 }}
                      alt={`${card.brand} Card`}
                    />{" "}
                    {uppercaseFirst(card.brand)} ending in {card.last4} Exp{" "}
                    {card.exp_month}/{card.exp_year}{" "}
                    {method?.metadata?.preauth_id ? (
                      <Tooltip title="Verified" placement="right">
                        <CheckCircleOutlined style={{ color: "green" }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Unverified" placement="right">
                        <CheckCircleOutlined style={{ color: "#cccccc" }} />
                      </Tooltip>
                    )}
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
        </Form.Item>
      )
    );
  };

  const handleMeteringBillingInfo = () => {
    Modal.info({
      icon: null,
      content: <MeteringBillingInfo />,
      centered: true,
      width: 800,
    });
  };

  const STEPS = [
    {
      title: "Database",
      content: (
        <div>
          <Paragraph type="secondary" style={{ marginBottom: "20px" }}>
            Select your desired Database Name and Size.
          </Paragraph>
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item
                label="Database Name"
                name="cluster_label"
                style={{ marginBottom: 12 }}
                required
              >
                <Input placeholder="My DB Instance" size="large" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Type" name="type" style={{ marginBottom: 12 }}>
                <Radio.Group
                  options={TYPES}
                  onChange={handleTypeChange}
                  optionType="button"
                  buttonStyle="solid"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <label style={{ marginBottom: 10, display: "block" }}>
            <label
              style={{
                color: "#ff4d4f",
                fontFamily: "SimSun, sans-serif",
                lineHeight: 1,
                fontSize: "14px",
                marginRight: "4px",
                display: "inline-block",
              }}
            >
              *
            </label>
            {TYPES.find((t) => t.value === type).label} Size{" "}
            <Text type="secondary" style={{ float: "right" }}>
              Click to select
            </Text>
          </label>
          <Row gutter={16} style={{ marginBottom: 20 }}>
            {products
              .filter(
                (product) =>
                  product.metadata.type === "compute" &&
                  product.metadata.subtype === type
              )
              .map((product, _, list) => {
                const { compute_product } = payload;
                const selected =
                  compute_product && compute_product.id === product.id;
                let nodes = "";
                let features = [];
                try {
                  if (product.metadata.features) {
                    features = JSON.parse(product.metadata.features);
                    nodes = features.shift();
                  }
                } catch (error) {}
                return (
                  <Col key={product.id} span={24 / list.length}>
                    <Card
                      title={
                        <div style={{ lineHeight: "16px" }}>
                          <strong style={{ fontSize: "16px" }}>
                            {product.name
                              .replace(/ CPU Database Usage/g, "")
                              .replace(/ GPU Database Usage/g, "")}
                          </strong>
                          <br />
                          {nodes}
                        </div>
                      }
                      onClick={handleInstanceSizeSelect(product)}
                      size="small"
                      extra={
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: selected ? 500 : 200,
                            lineHeight: "14px",
                            textAlign: "right",
                            marginLeft: 5,
                            color: selected
                              ? SIZE_COLORS[product.metadata.size]
                              : `${SIZE_COLORS[product.metadata.size]}99`,
                          }}
                        >
                          <strong>{type.toUpperCase()}</strong>
                          <br />
                          {DollarFormatter.format(
                            product.price.unit_amount / 100
                          )}{" "}
                          /{product.unit_label}
                        </div>
                      }
                      style={
                        selected
                          ? {
                              border: `2px solid ${
                                SIZE_COLORS[product.metadata.size]
                              }66`,
                              borderRadius: 5,
                              cursor: "pointer",
                            }
                          : {
                              border: `2px solid ${
                                SIZE_COLORS[product.metadata.size]
                              }22`,
                              borderRadius: 5,
                              cursor: "pointer",
                            }
                      }
                      headStyle={
                        selected
                          ? {
                              backgroundColor: `${
                                SIZE_COLORS[product.metadata.size]
                              }33`,
                              color: SIZE_COLORS[product.metadata.size],
                              padding: "0px 10px",
                            }
                          : {
                              fontWeight: "normal",
                              backgroundColor: `${
                                SIZE_COLORS[product.metadata.size]
                              }11`,
                              color: `${SIZE_COLORS[product.metadata.size]}99`,
                              padding: "0px 10px",
                            }
                      }
                      bodyStyle={
                        selected
                          ? {
                              backgroundColor: `${
                                SIZE_COLORS[product.metadata.size]
                              }22`,
                              color: SIZE_COLORS[product.metadata.size],
                              padding: "5px 10px",
                              height: 88,
                            }
                          : {
                              backgroundColor: `${
                                SIZE_COLORS[product.metadata.size]
                              }09`,
                              color: `${SIZE_COLORS[product.metadata.size]}77`,
                              padding: "5px 10px",
                              height: 88,
                            }
                      }
                    >
                      <strong style={{ fontWeight: 500 }}>
                        Total Resources
                      </strong>
                      :
                      <ul
                        style={{
                          listStyle: "none",
                          margin: 0,
                          padding: 0,
                          fontSize: "12px",
                          lineHeight: "14px",
                        }}
                      >
                        {features.map((feature, idx) => (
                          <li key={idx}>{feature}</li>
                        ))}
                      </ul>
                    </Card>
                  </Col>
                );
              })}
          </Row>
          <Row>
            <Col>
              <div
                style={{
                  color: "#999999",
                  fontWeight: 300,
                  fontSize: "13px",
                  lineHeight: "16px",
                }}
              >
                * Dedicated fees will only be charged for the hours your cluster
                is running (vs. suspended).
                <br />* Data storage and transfer fees will be charged at the
                cloud provider rates.
                <br />* Price does not include local taxes.
                <br />* For more details on how we bill,{" "}
                <Button
                  type="link"
                  onClick={handleMeteringBillingInfo}
                  style={{
                    padding: 0,
                    height: "13px",
                    fontSize: "13px",
                    verticalAlign: "text-top",
                    top: "-3px",
                  }}
                >
                  click here
                </Button>
                .
              </div>
            </Col>
          </Row>
          {/* <Row gutter={32}>
            <Col span={12}>
              <label style={{ marginBottom: 10, display: "block" }}>
                Storage
              </label>
              <Form.Item
                label=""
                name="storage_product_id"
                style={{ marginBottom: 0 }}
              >
                <Radio.Group size="large" style={{ width: "100%" }}>
                  {products
                    .filter((product) => product.metadata.type === "storage")
                    .map((product) => {
                      return (
                        <Radio.Button
                          key={product.id}
                          value={product.id}
                          style={{
                            fontSize: "14px",
                            border: "2px solid #ac92d6",
                            backgroundColor: "#ac92d607",
                            cursor: "not-allowed",
                            width: "100%",
                          }}
                        >
                          <span style={{ fontWeight: 600, width: "100%" }}>
                            {product.name.replace(/ Usage/g, "")}
                            <label
                              style={{
                                fontSize: "14px",
                                fontWeight: 200,
                                marginLeft: 5,
                              }}
                            >
                              {DollarFormatter.format(product.price.unit_amount / 100)} /
                              {product.unit_label}
                            </label>
                          </span>
                        </Radio.Button>
                      );
                    })}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <label style={{ marginBottom: 10, display: "block" }}>
                Network
              </label>
              <Form.Item
                label=""
                name="network_product_id"
                style={{ marginBottom: 0 }}
              >
                <Radio.Group size="large" style={{ width: "100%" }}>
                  {products
                    .filter((product) => product.metadata.type === "network")
                    .map((product) => {
                      return (
                        <Radio.Button
                          key={product.id}
                          value={product.id}
                          style={{
                            fontSize: "14px",
                            border: "2px solid #ac92d6",
                            backgroundColor: "#ac92d607",
                            cursor: "not-allowed",
                            width: "100%",
                          }}
                        >
                          <span style={{ fontWeight: 600, width: "100%" }}>
                            {product.name.replace(/ Usage/g, "")}
                            <label
                              style={{
                                fontSize: "14px",
                                fontWeight: 200,
                                marginLeft: 5,
                              }}
                            >
                              {DollarFormatter.format(product.price.unit_amount / 100)} /
                              {product.unit_label}
                            </label>
                          </span>
                        </Radio.Button>
                      );
                    })}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row> */}
        </div>
      ),
      validate: () => {
        const {
          cluster_label,
          compute_product,
          storage_product,
          network_product,
        } = payload;
        const errors = [];
        if (!cluster_label) {
          errors.push("Database name not specified");
        } else if (cluster_label.length > 20) {
          errors.push("Database name must be 20 characters or less");
        }
        if (!compute_product) {
          errors.push("Instance Size not selected");
        }
        if (!storage_product) {
          errors.push("Storage not selected");
        }
        if (!network_product) {
          errors.push("Network not selected");
        }
        if (errors.length > 0) {
          handleError(errors);
        }
        return errors.length === 0;
      },
    },
    {
      title: "Security",
      content: (
        <div>
          <Paragraph type="secondary" style={{ marginBottom: "20px" }}>
            Set a password for your primary admin account.
          </Paragraph>
          <Form.Item label="Admin Username" name="admin_username">
            <Input size="large" />
          </Form.Item>
          <Tooltip
            placement="right"
            title="Must contain 8 characters, 1 uppercase, 1 lowercase, and 1 number."
          >
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  label="Password"
                  name="admin_password"
                  defaultValue=""
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                  validateStatus={passwordStrength?.status}
                  hasFeedback
                >
                  <Password size="large" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Confirm Password"
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                  ]}
                >
                  <Password size="large" />
                </Form.Item>
              </Col>
            </Row>
          </Tooltip>
        </div>
      ),
      validate: () => {
        const { admin_username, admin_password, confirm_password } = payload;

        const validUsername = checkValidUsername(admin_username);
        const strength = checkPasswordStrength(admin_password);

        const errors = [];

        if (!admin_username) {
          errors.push("Admin Username not selected");
        } else if (!validUsername) {
          errors.push("Admin Username not allowed");
        }

        if (!admin_password) {
          errors.push("Password not selected");
        } else if (strength.value !== "strong") {
          errors.push(
            "Password must contain 8 characters, 1 uppercase, 1 lowercase, and 1 number."
          );
        }

        if (admin_password !== confirm_password) {
          errors.push("Confirm Password must match password");
        }

        if (errors.length > 0) {
          handleError(errors);
        }

        return errors.length === 0;
      },
    },
    {
      title: "Billing",
      content: (
        <div>
          <Paragraph type="secondary" style={{ marginBottom: "20px" }}>
            Add and/or select a default global payment method for all your
            invoices.
          </Paragraph>
          <label style={{ marginBottom: 10, display: "block" }}>
            Payment Method
          </label>
          {!paymentMethodsLoading && renderPaymentMethods(paymentMethods)}
          {enableAddCard ? (
            <Row gutter={20}>
              <Col span={18}>
                <Form.Item name="new_payment_method">
                  <div
                    style={{
                      padding: "10px 15px",
                      backgroundColor: "#ac92d611",
                      borderRadius: 8,
                    }}
                  >
                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  onClick={handleAddPaymentMethod}
                  loading={paymentMethodsAdding}
                  style={{ borderRadius: 10, width: "100%", margin: "4px 0" }}
                  ghost
                >
                  Add Card
                </Button>
              </Col>
            </Row>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                setEnableAddCard(true);
              }}
              style={{ borderRadius: 10, width: "100%" }}
              ghost
            >
              Add Payment Method
            </Button>
          )}
          <div
            style={{
              textAlign: "center",
              padding: "10px",
              fontSize: "12px",
              color: "#cccccc",
            }}
          >
            Powered by{" "}
            <img src={StripeLogo} style={{ height: 28 }} alt={`Stripe`} />
          </div>
        </div>
      ),
      validate: () => {
        const { payment_method } = payload;
        const errors = [];
        if (!payment_method) {
          errors.push("Payment Method not selected");
        }
        if (errors.length > 0) {
          handleError(errors);
        }
        return errors.length === 0;
      },
    },
    {
      title: "Summary",
      content: (
        <div>
          <Paragraph type="secondary" style={{ marginBottom: "20px" }}>
            Please review and confirm the information below. You must also read
            and agree to the terms and conditions before continuing. For more
            details on how we bill,{" "}
            <Button
              type="link"
              onClick={handleMeteringBillingInfo}
              style={{
                padding: 0,
                height: "14px",
                fontSize: "14px",
                verticalAlign: "text-top",
                top: "-3px",
              }}
            >
              click here
            </Button>
            .
          </Paragraph>
          {payload?.cluster_label &&
            payload?.compute_product &&
            payload?.storage_product &&
            payload?.network_product && (
              <Descriptions column={1} size="small" bordered>
                <Descriptions.Item label="Label">
                  {payload?.cluster_label}
                </Descriptions.Item>
                <Descriptions.Item label="Size">
                  {payload?.compute_product.name
                    .replace(/ Database Usage/g, "")
                    .replace(/ Database Usage/g, "")}
                  {/* <label
                    style={{
                      fontSize: "14px",
                      fontWeight: 200,
                      float: "right",
                    }}
                  >
                    {DollarFormatter.format(
                      payload?.compute_product.price.unit_amount / 100
                    )}{" "}
                    /{payload?.compute_product.unit_label}
                  </label> */}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Storage">
                  {payload?.storage_product.name.replace(/ Usage/g, "")}
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: 200,
                      float: "right",
                    }}
                  >
                    {DollarFormatter.format(
                      payload?.storage_product.price.unit_amount / 100
                    )}
                    {payload?.storage_product.unit_label && (
                      <> /{payload?.storage_product.unit_label}</>
                    )}
                  </label>
                </Descriptions.Item> */}
                {/* <Descriptions.Item label="Network">
                  {payload?.network_product.name.replace(/ Usage/g, "")}
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: 200,
                      float: "right",
                    }}
                  >
                    {DollarFormatter.format(
                      payload?.network_product.price.unit_amount / 100
                    )}
                    {payload?.network_product.unit_label && (
                      <> /{payload?.network_product.unit_label}</>
                    )}
                  </label>
                </Descriptions.Item> */}
                <Descriptions.Item label="Admin Username">
                  {payload?.admin_username}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Method">
                  {payload.payment_method &&
                    renderPaymentMethod(payload.payment_method)}
                </Descriptions.Item>
              </Descriptions>
            )}
          <Form.Item
            name="agreement"
            valuePropName="checked"
            defaultChecked={false}
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error("You must agree to continue")),
              },
            ]}
            style={{ marginTop: 20, marginBottom: 0 }}
          >
            <Checkbox>
              I have read and agree to the{" "}
              <a
                href="https://www.kinetica.com/agreements/cloud-services-agreement/"
                target="_blank"
                rel="noreferrer"
              >
                Kinetica terms and conditions
              </a>
            </Checkbox>
          </Form.Item>
          {preauthAmount > 0 &&
            !payload.payment_method?.metadata?.preauth_id && (
              <Form.Item
                name="preauth"
                valuePropName="checked"
                defaultChecked={false}
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("You must agree to continue")
                          ),
                  },
                ]}
                style={{ marginTop: 0 }}
              >
                <Checkbox>
                  I agree to a hold authorization of $
                  {(Math.round(preauthAmount) / 100).toFixed(2)} on the selected
                  payment method
                </Checkbox>
              </Form.Item>
            )}
        </div>
      ),
      validate: () => {
        const errors = [];
        if (!form.getFieldValue("agreement")) {
          errors.push("You must read and agree to the terms and conditions");
        }
        if (
          preauthAmount > 0 &&
          !payload.payment_method?.metadata?.preauth_id &&
          !form.getFieldValue("preauth")
        ) {
          errors.push(
            `You must agree to a hold authorization of $${(
              Math.round(preauthAmount) / 100
            ).toFixed(2)} on the selected payment method`
          );
        }
        if (errors.length > 0) {
          handleError(errors);
        }
        return errors.length === 0;
      },
    },
  ];

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.password) {
      if (changedValues.password !== "") {
        const strength = checkPasswordStrength(changedValues.password);
        setPasswordStrength(strength);
      } else {
        setPasswordStrength(false);
      }
    }

    if (changedValues?.compute_product_id) {
      setPayload({
        ...payload,
        compute_product: products.find(
          (product) => product.id === changedValues?.compute_product_id
        ),
      });
    } else if (changedValues?.payment_method_id) {
      setPayload({
        ...payload,
        payment_method: paymentMethods.find(
          (paymentMethod) =>
            paymentMethod.id === changedValues?.payment_method_id
        ),
      });
    } else {
      setPayload({
        ...payload,
        ...allValues,
      });
    }
  };

  const next = async () => {
    if (STEPS[current].validate()) {
      if (STEPS[current].submit) {
        const success = await STEPS[current].submit();
        if (success) {
          setCurrent(current + 1);
        }
      } else {
        setCurrent(current + 1);
      }
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const cancel = () => {
    history.push("/dashboard");
  };

  const handleCreateDatabase = async () => {
    if (!STEPS[current].validate()) {
      return false;
    }

    try {
      setDatabaseCreating(true);

      const token = await auth.getIdToken();

      if (preauthAmount > 0 && !payload.payment_method?.metadata?.preauth_id) {
        // Pre-authorize payment method
        const preauthResp = await axios.post(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/${payload.payment_method.id}/preauth`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { payment_method } = preauthResp.data;
        setPayload({
          ...payload,
          payment_method,
        });

        const paymentMethodsResp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/customer`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { payment_methods } = paymentMethodsResp.data;
        setPaymentMethods(payment_methods);
      }

      await axios.put(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/customers`,
        {
          address: {
            postal_code:
              payload.payment_method.billing_details.address.postal_code,
            country: payload.payment_method.card.country,
          },
          invoice_settings: {
            default_payment_method: payload.payment_method.id,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if we need to create a new subscription or use and existing one
      const resp = await axios.get(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/subscriptions/customer`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { subscriptions: subscriptions1 } = resp.data;

      if (subscriptions1.length === 0) {
        // Create new subscription
        await axios.post(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/subscriptions`,
          {
            items: [
              { price: payload.compute_product.default_price },
              { price: payload.storage_product.default_price },
              { price: payload.network_product.default_price },
            ],
            automatic_tax: {
              enabled: true,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        // Merge applicable items
        const { items: existing } = subscriptions1[0];
        const { data } = existing;

        const existingItems = data.map((item) => ({ price: item.price.id }));

        const items = [
          { price: payload.compute_product.default_price },
          { price: payload.storage_product.default_price },
          { price: payload.network_product.default_price },
        ].filter((update) => {
          return !existingItems.some(
            (existing) => update.price === existing.price
          );
        });

        // Update existing subscription
        await axios.put(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/subscriptions/${subscriptions1[0].id}`,
          {
            items,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      const {
        cluster_label,
        compute_product,
        storage_product,
        network_product,
        admin_username,
        admin_password,
      } = payload;
      const params = {
        cluster_label,
        compute_product,
        storage_product,
        network_product,
        admin_username,
        admin_password,
      };

      await axios.post(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/dedicated/provision`,
        params,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Segment
      segment.track("provision_dedicated");

      notification.success({
        message: "Dedicated Database Provision",
        description: "Database is being provisioned.",
      });
      history.push("/dashboard");
    } catch (error) {
      handleError(error);
      setDatabaseCreating(false);
    }
  };

  return (
    <Form
      form={form}
      initialValues={{
        cluster_label: "",
        type: TYPES[0].value,
        compute_product_id: "",
        storage_product_id: products.find(
          (product) => product.metadata.type === "storage"
        ).id,
        network_product_id: products.find(
          (product) => product.metadata.type === "network"
        ).id,
        payment_method_id: "",
        admin_username: "kadmin",
        admin_password: "",
        confirm_password: "",
        agreement: false,
      }}
      layout="vertical"
      onValuesChange={onValuesChange}
    >
      <Steps current={current}>
        <Step title={STEPS[0].title} icon={<CloudServerOutlined />} />
        <Step title={STEPS[1].title} icon={<LockOutlined />} />
        <Step title={STEPS[2].title} icon={<CreditCardOutlined />} />
        <Step title={STEPS[3].title} icon={<CreditCardOutlined />} />
      </Steps>
      <div
        style={{
          minHeight: 390,
          margin: "10px 0 20px",
          padding: 0,
          borderRadius: 10,
        }}
      >
        {STEPS[current].content}
      </div>
      <div style={{ float: "right" }}>
        {current < STEPS.length - 1 && (
          <Button
            type="primary"
            onClick={() => next()}
            style={{ borderRadius: 10 }}
          >
            Next
          </Button>
        )}
        {current === STEPS.length - 1 && (
          <Button
            type="primary"
            onClick={handleCreateDatabase}
            loading={databaseCreating}
            style={{ borderRadius: 10 }}
          >
            Create
          </Button>
        )}
      </div>
      <div style={{ float: "left" }}>
        <Space>
          <Button onClick={() => cancel()} style={{ borderRadius: 10 }}>
            Cancel
          </Button>
          {current > 0 && (
            <Button
              type="primary"
              onClick={() => prev()}
              style={{ borderRadius: 10 }}
              ghost
            >
              Previous
            </Button>
          )}
        </Space>
      </div>
    </Form>
  );
};

export default Wizard;
