import { useState, useRef, useCallback, useEffect } from "react";
import { notification } from "antd";
import { PASSWORD_STRONG, PASSWORD_MEDIUM } from "./constants";

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const useStateCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null);

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb;
    setState(state);
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
};

const replaceEmailLink = (str, searchStr) => {
  if (typeof str === "string" && str.split(searchStr).length > 1) {
    const parts = str.split(searchStr);
    return (
      <>
        {parts[0]} <a href={`mailto:${searchStr}`}>{searchStr}</a> {parts[1]}
      </>
    );
  }
  return str;
};

export const handleError = (error) => {
  if (Array.isArray(error)) {
    notification.error({
      message: "Error",
      description: error.map((error, idx) => (
        <div key={idx}>{replaceEmailLink(error, "success@kinetica.com")}</div>
      )),
      duration: 0,
    });
  } else if (error?.response?.data?.errors) {
    notification.error({
      message: "Error",
      description: replaceEmailLink(
        error?.response?.data?.errors,
        "success@kinetica.com"
      ),
      duration: 0,
    });
  } else {
    notification.error({
      message: "Error",
      description:
        replaceEmailLink(error?.message, "success@kinetica.com") ||
        replaceEmailLink(error, "success@kinetica.com"),
      duration: 0,
    });
  }
};

export const checkValidUsername = (username) => {
  const BLACKLIST = ["admin", "planner", "graph"];
  if (BLACKLIST.includes(username.toLowerCase())) {
    return false;
  } else if (!username.match(/^[a-zA-Z0-9-]{5,10}$/)) {
    return false;
  }
  return true;
};

export const checkPasswordStrength = (password) => {
  if (PASSWORD_STRONG.test(password)) {
    return {
      value: "strong",
      status: "success",
    };
  } else if (PASSWORD_MEDIUM.test(password)) {
    return {
      value: "medium",
      status: "warning",
    };
  } else {
    return {
      value: "weak",
      status: "error",
    };
  }
};

export const uppercaseFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const validateEmail = (email) => {
  if (email.match(/^\d/)) {
    throw Error(
      "Email addresses beginning with a number are currently not supported. Contact success@kinetica.com if you need assistance."
    );
  }
  return;
};

export const updateInvoiceLineItemDesc = (input) => {
  if (
    input.toLowerCase().includes("cloud storage") ||
    input.toLowerCase().includes("data transfer")
  ) {
    const regex = /\d+ (.*) × (.*)/gi;
    const unitMatch = regex.exec(input.replace(/ \(.*?\)/, ""));
    return unitMatch.length === 3 ? `${unitMatch[2]}` : null;
  }
  return input.replace(/ \(.*?\)/, "").replace(" Usage", "");
};
